<template>
  <div class="d-flex align-items-end">
    <img src="@/assets/img/logo/birutekno.svg" alt="">
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>