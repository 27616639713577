import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   // will match everything
  //   path: '*',
  //   name: 'NotFound',
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "@/views/NotFound.vue"),
  // },
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/nav",
    name: "NotFound",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/components/NavbarMobile.vue"),
  },
  {
    path: "/about",
    redirect: '/about',
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/about/About.vue"),
    children: [
      {
        path: "profile",
        name: "Profile",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/about/Profile.vue"),
      },
      {
        path: "company-profile",
        name: "CompanyProfile",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/company/CompanyProfile.vue"),
      },
      {
        path: "contact",
        name: "Contact",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/about/ContactUs.vue"),
      },
      {
        path: "career",
        name: "Career",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/about/Career.vue"),
      },
      {
        path: "product",
        name: "Product",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/about/Product.vue"),
      },
      {
        path: "support",
        name: "Support",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/about/Support.vue"),
      },
      {
        path: "success-story",
        name: "Success-story",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/about/successStory.vue"),
      },
      {
        path: "success-story-detail",
        name: "Success-story-detail",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/about/successStoryDetail.vue"),
      },
      {
        path: "support-detail",
        name: "Support-detail",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ "@/views/about/SupportDetail.vue"),
      },
    ]
  },
  {
    path: "/getstarted",
    name: "getstarted",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/GetStarted.vue"),
  },
  {
    path: "/education",
    name: "education",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Education.vue"),
  },
  {
    path: "/ai-solution",
    name: "aisolution",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/AiSolution.vue"),
  },

  {
    path: "/expertise",
    name: "expertise",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/about/Expertise.vue"),
  },
  {
    path: "/jobs",
    name: "jobs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/about/Jobs.vue"),
  },
  {
    path: "/enterprise-project",
    name: "enterprise-project",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/about/EnterpriseProject.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/about/Profile.vue"),
  },
  {
    path: "/support",
    name: "support",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/about/Support.vue"),
  },
  {
    path: "/support-detail",
    name: "support-detail",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/about/SupportDetail.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/about/ContactUs.vue"),
  },
  {
    path: "/payroll-management",
    name: "payroll management",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/solutions/PayrollManagement.vue"),
  },
  {
    path: "/project-management",
    name: "project management",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/solutions/ProjectManagement.vue"),
  },
  {
    path: "/learning-management",
    name: "learning management",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/solutions/LearningManagement.vue"),
  },
  {
    path: "/sales-management",
    name: "sales management",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/solutions/SalesManagement.vue"),
  },
  {
    path: "/proctoring-ai",
    name: "proctoring-ai",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/solutions/ProctoringAi.vue"),
  },
  {
    path: "/hcm",
    name: "HCM",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/solutions/HCM.vue"),
  },
  {
    path: "/enterprise-solutions",
    name: "enterprise-solutions",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/EnterpriseSolutions.vue"),
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Portfolio.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
});

export default router;
