<template>
  <img :src="gambar" alt="" />
</template>

<script>
export default {
  props: ["img"],
  computed: {
    gambar: function () {
      return require("../assets/img/" + this.img);
    },
  },
};
</script>

<style>
</style>