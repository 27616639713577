<template>
  <div
    class="
      bg-orange
      rounded-xl
      w-32
      xl:w-52 xl:m-0 xl:text-xl
      py-2 py-md-3
      text-center
    "
  >
    <router-link
      :to="{ name: 'getstarted' }"
      class="text-decoration-none text-white"
    >
      Get Started
    </router-link>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>