<template>
  <div id="app">
    <template v-if="$route.name != 'NotFound'">
      <Navbar />
      <!-- <NavbarMobile /> -->
    </template>
    <router-view id="router-view" class="filter"></router-view>
    <template v-if="$route.name != 'NotFound'">
      <Footer />
    </template>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
// import NavbarMobile from "@/components/NavbarMobile.vue";
1;
import Footer from "@/components/Footer.vue";

export default {
  name: "Home",
  components: {
    // NavbarMobile,
    Navbar,
    Footer,
  },
  created() {
    window.addEventListener("click", (e) => {
      console.log(e.target);
      if (e.target.id !== "navbar-mobile") {
        console.log(e.target.classList.contains("hidden"));
        console.log(e.target);

        // this.$store.dispatch("bars");
      }
    });
  },
};
</script>


<style>
@import "~bootstrap/dist/css/bootstrap.css";
@import "./assets/css/main.css";
@import "~animate.css";
.bg-gray {
  background: #f9f9f9;
}
.bg-color-gray {
  background-color: #e5e5e5;
}
body {
  padding-top: 4.5rem;
  font-family: Poppins;
}
.bg-orange {
  background: #f6a21e;
}
.text-orange {
  color: #f6a21e;
}
.border-orange {
  border: 1px solid #f6a21e;
}
.blue-1 {
  background: #f2f7ff;
}
.blue-2 {
  background: #eaf2ff;
}
.blue-3 {
  background: #e0ecff;
}
.blue-4 {
  background: #d6e5ff;
}
.blue-5 {
  background: #cddfff;
}
.blue-6 {
  background: #c1d8ff;
}
</style>
