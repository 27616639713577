<template>
  <div class="overflow-hidden">
    <div class="bg-white pt-5">
      <div class="container pt-4 relative">
        <div class="row">
          <div class="col-6">
            <h1 class="text-primary text-xl md:text-4xl font-bold mb-md-4">
              The World is going
            </h1>
            <p
              class="
                digital
                text-primary text-4xl
                md:text-7xl
                lg:text-8xl
                font-bold
              "
              style="letter-spacing: 0.2em"
            >
              DIGITAL
            </p>
            <h3 class="mb-3 text-base md:text-2xl md:leading-loose">
              Transform your business for getting Smarter and Seamless
            </h3>
            <GetStarted class="z-50" />
          </div>

          <div class="col-6">
            <img
              src="@/assets/img/hero.svg"
              class="d-block img-fluid"
              alt="img"
              width="500"
              height="445"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <img
        src="@/assets/img/hero2.svg"
        alt=""
        width="100%"
        class="-mt-5 md:-mt-24 lg:-mt-32"
        style="z-indez: -10"
      />
    </div>
    <!-- end hero -->
    <!-- solution -->
    <div class="row">
      <div class="col-md-4 text-center p-5" style="background: #f2f7ff">
        <img
          src="@/assets/img/Work.svg"
          alt="img"
          class="pb-3 mx-auto w-14 h-14"
        />
        <h5 class="text-lg fw-bold">Enterprise Solutions</h5>
        <p class="font-normal">
          Enterprise cloud management with modern planning for digital workplace
        </p>
        <div class="flex justify-center space-x-1 align-items-center font-semibold">
          <router-link to="/enterprise-solutions" class="text-decoration-none"
            >details</router-link
          >
          <b-icon icon="arrow-right" class="text-primary"></b-icon>
        </div>
      </div>
      <div class="col-md-4 text-center p-5" style="background: #eaf2ff">
        <img
          src="@/assets/img/Book.svg"
          alt="img"
          class="pb-3 mx-auto w-14 h-14"
        />
        <h5 class="text-lg fw-bold">Education Solutions</h5>
        <p class="font-normal">
          Focus on student and organizations success with smarter platform
        </p>
        <div class="flex justify-center space-x-1 align-items-center font-semibold">
          <router-link to="education" class="text-decoration-none"
            >details</router-link
          >
          <b-icon icon="arrow-right" class="text-primary"></b-icon>
        </div>
      </div>
      <div class="col-md-4 text-center p-5" style="background: #e0ecff">
        <img
          src="@/assets/img/Face Now.svg"
          alt="img"
          class="pb-3 mx-auto w-14 h-14"
        />
        <h5 class="text-lg fw-bold">AI Solutions</h5>
        <p class="font-normal">
          An AI platform and services that focus on Vision & NLP project
        </p>
        <div class="flex justify-center space-x-1 align-items-center font-semibold">
          <router-link to="/ai-solution" class="text-decoration-none"
            >details</router-link
          >
          <b-icon icon="arrow-right" class="text-primary"></b-icon>
        </div>
      </div>
    </div>
    <!-- end solution -->
    <!-- visi misi -->
    <div>
      <div class="row visi">
        <div class="col-md-6 bg-white p-5 h-96">
          <div class="container-lg ms-lg-5">
            <h3 class="text-primary">Who We Are</h3>
            <p class="fw-bold">
              We have strong combination skills. High curiosity
            </p>
            <p class="mb-5">
              and also never feel satisfied quickly to build a great software
            </p>
            <p class="italic">
              “Birutekno is intersection between art and technology”
            </p>
          </div>
        </div>
        <div class="col-md-6 bg-in p-5 h-96">
          <div class="container-lg ms-lg-3">
            <h3 class="text-white">Our Spirit</h3>
            <p class="mb-3 text-white fw-bold 2xl:font-bold">
              We have seen how institutions around us : business, profit,
              non-profit as well goverment sectors are struggling
            </p>
            <p class="mb-3 text-white">
              In managing and transforming themselves into effective DIGITALLY
              COMPATIBLE institutions.
            </p>
            <p class="mb-3 text-white">
              For that reason, we create a team that consists of combinations of
              all kinds with relevant experiences and expertise to serve and
              assist those institutions that are in pain in making the
              transformation effective.
            </p>
          </div>
        </div>
      </div>
      <div class="row misi">
        <div class="col-md-6 bg-in p-5 h-96">
          <div class="container-lg ms-lg-5">
            <h3 class="text-white">Our Mission</h3>
            <p class="mb-3 text-white">
              To remove pains from any institutions which are in the process of
              digital transformation and making it happen successfully
            </p>
          </div>
        </div>
        <div class="col-md-6 bg-white p-5 h-96">
          <div class="container-lg ms-lg-3">
            <h3 class="text-primary">Our Values</h3>
            <ol class="lh-lg p-0">
              <li>
                <span
                  ><span class="text-primary text-xl"> &bull;</span>
                  Collaborative in addressing given situation</span
                >
              </li>
              <li>
                <span
                  ><span class="text-primary text-xl"> &bull;</span> Open in
                  sharing problems and solutions</span
                >
              </li>
              <li>
                <span
                  ><span class="text-primary text-xl"> &bull;</span> Result
                  oriented</span
                >
              </li>
              <li>
                <span
                  ><span class="text-primary text-xl"> &bull;</span> Economic
                  enhancer</span
                >
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <!-- end visi misi -->
    <!-- core -->
    <div class="position-relative">
      <div class="container bg-core py-5">
        <h2 class="fw-bold pt-5 text-2xl">Core Technology</h2>
        <p class="mt-4">
          We build on a flexible foundation that enables us to continually
          broaden our technology platform. We're working to bring you
          increasingly intelligent and personalized experiences.
        </p>
        <div class="container">
          <div class="row pt-4">
            <div class="col-md-1 p-lg-0">
              <img
                src="@/assets/img/machine learning.svg"
                alt="machine learning"
                width=""
              />
            </div>
            <div class="col-md-11 px-lg-0">
              <p class="text-lg fw-bold">Machine Learning</p>
              <p class="">
                Enable better judgement to surface insights, so you can make
                more-informed decisions faster.
              </p>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-md-1 p-0">
              <img src="@/assets/img/Blockchain.svg" alt="Blockchain" />
            </div>
            <div class="col-md-11 px-lg-0">
              <p class="text-lg fw-bold">Blockchain</p>
              <p class="">
                Transaction & security enhancement with smartcontract or any
                Blockchain technology
              </p>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-md-1 p-0">
              <img
                src="@/assets/img/augment reality.svg"
                alt="augment reality"
              />
            </div>
            <div class="col-md-11 px-lg-0">
              <p class="text-lg fw-bold">Augmented Reality</p>
              <p class="">
                Shaping the future of customer engagement with AR experience
              </p>
            </div>
          </div>
          <div class="row pt-4">
            <div class="col-md-1 p-0">
              <img src="@/assets/img/cloud.svg" alt="cloud" />
            </div>
            <div class="col-md-11 px-lg-0">
              <p class="text-lg fw-bold">Cloud Solutions</p>
              <p class="">
                Manage and access your apps with better infrastructure over
                multiple locations
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end core -->
    <!-- succesProject -->

    <div class="bg-gray py-3 mt-5">
      <div class="container">
        <h2 class="fw-bold text-2xl">
          Successful case with unique and complex problem
        </h2>
        <p class="my-4 w-10/12">
          In our journey as software developer and IT consultant we have some
          expertise to understand the main of pain from any industries. We know
          how to solve it, go explore now !
        </p>
      </div>
      <!-- carousel success project -->
      <div class="container">
        <div class="">
          <carousel
            class="successP"
            autoWidth
            stagePadding="50"
            :nav="false"
            :dots="false"
          >
            <div
              class="w-80 2xl:w-96 bg-white rounded-xl mr-5"
              v-for="(data, i) in successProject"
              :key="i"
              style="height: 450px"
            >
              <Img
                :img="data.imgTop"
                style="width: 100%; max-height: 250px"
                class="object-fit"
              />
              <div class="p-3">
                <p class="fw-bold text-lg h-8 mb-0">
                  {{ data.title }}
                </p>
                <p class="text-muted h-12">{{ data.subtitle }}</p>
                <div class="d-flex space-x-2">
                  <Img
                    :img="item"
                    class=""
                    v-for="item in data.logo"
                    :key="item"
                  />
                </div>
              </div>
            </div>
          </carousel>
        </div>
      </div>
      <div class="container">
        <h2 class="mt-5 font-bold text-2xl">
          Our customers achieve amazing results
        </h2>
      </div>
      <!-- end succesProject -->
      <!-- partner -->
      <div class="container partner">
        <carousel
          autoWidth
          autoHeight
          autoplayTimeout="1300"
          :nav="false"
          items="4"
          loop
        >
          <div class="p-3" v-for="(data, i) in partner" :key="i">
            <div style="width: 250px; height: 120px" class="flex items-center">
              <Img :img="data.logo" class="mx-auto" />
            </div>
          </div>
        </carousel>
      </div>
      <!-- <div
          id="successProject"
          class="carousel slide py-5"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#successProject"
              :data-bs-slide-to="item"
              :class="[
                { active: partnerCurrentPage == --item, 'bg-primary': true },
              ]"
              aria-current="true"
              :aria-label="'Slide' + item"
              v-for="item in jumlahPagePartner"
              :key="item"
              @click="selectPagePartner(item)"
            ></button>
            <button
              @click="$router.push('/customers')"
              class="ms-3 text-primary fw-bold"
            >
              see all <i class="fas fa-arrow-right"></i>
            </button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active pb-5">
              <div class="row justify-center partner h-24">
                <div
                  class="col mb-3 align-self-center"
                  v-for="(data, i) in partnerShow"
                  :key="i"
                >
                  <Img :img="data.logo" class="mx-auto" />
                </div>
              </div>
            </div>
          </div>
        </div> -->
      <!-- end partner -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Img from "@/components/Img";
import GetStarted from "@/components/GetStarted";
import carousel from "vue-owl-carousel2";
export default {
  name: "Home",
  components: { carousel, Img, GetStarted },
  data() {
    return {
      partner: [
        {
          logo: "Epson.png",
        },
        {
          logo: "logo_bni.png",
        },

        {
          logo: "logo_dtpeduli.svg",
        },
        {
          logo: "IAPI.png",
        },
        {
          logo: "logo_dusunBambu.svg",
        },
        {
          logo: "logo_ytruck.svg",
        },
        {
          logo: "cca.png",
        },
        {
          logo: "logo_ytruck.svg",
        },
        {
          logo: "logo_jualaja.svg",
        },

        {
          logo: "logo_bpom.svg",
        },
        {
          logo: "logo_heksa.svg",
        },
        {
          logo: "logo_itb.svg",
        },
      ],
      successProject: [
        {
          title: "Project Manajement",
          subtitle: "Make Your Business Online and under Control.",
          imgTop: "p1.png",
          logo: ["logo_acset.svg"],
        },
        {
          title: "Analytics Dashboard",
          subtitle: "Make Your Business Online and under Control.",
          imgTop: "p2.png",
          logo: ["logo_sm_dapenbi.svg", "sm_logo_ykkbi.png"],
        },
        {
          title: "LMS",
          subtitle: "Make Your Business Online and under Control.",
          imgTop: "p3.png",
          logo: ["logo_sm_iapi.svg"],
        },
        {
          title: "System integration",
          subtitle: "Make Your Business Online and under Control.",
          imgTop: "p4.png",
          logo: ["logo_sm_ytruck.svg"],
        },
        {
          title: "LMS",
          subtitle: "Make Your Business Online and under Control.",
          imgTop: "p3.png",
          logo: ["logo_sm_iapi.svg"],
        },
        {
          title: "System integration",
          subtitle: "Make Your Business Online and under Control.",
          imgTop: "p4.png",
          logo: ["logo_sm_ytruck.svg"],
        },
      ],
    };
  },
};
</script>
<style >
.successP .owl-carousel .owl-item img {
  width: auto;
}
.partner .owl-carousel .owl-item img {
  min-width: 130px;
  max-width: 110px;
  min-height: 42px;
  max-height: 75px;
}
.partner .owl-carousel .owl-stage {
  padding: 20px 0px;
  box-sizing: border-box;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.digital {
  font-family: "Chakra Petch", sans-serif;
}
.visi {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  /* or 156% */
}
.visi h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height */
}
.visi h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  /* or 156% */
}
.misi h3 {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
  /* identical to box height */
}
.misi h5 {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  /* or 156% */
}
.bg-in {
  background-color: #1e4db0;
}
/* core technology */
.bg-core::before {
  content: "";
  background-image: url("../assets/img/bg-core.png");
  background-size: cover;
  width: 50%;
  right: 0;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: -1;
  overflow: hidden;
}
.bg-core::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 0)
  );
  z-index: -1;
}
</style>
