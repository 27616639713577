import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
export const store = new Vuex.Store({
    state: {
        count: 0
    },
    mutations: {
        increment(state) {
            state.count++
        }
    },
    actions: {
        back() {
            window.history.back()
        },
        bars() {
            let top = document.getElementById("top-bar");
            let mid = document.getElementById("middle-bar");
            let bot = document.getElementById("bottom-bar");
            top.classList.toggle("top-bar-rotate");
            mid.classList.toggle("open");
            bot.classList.toggle("bot-bar-rotate");
            let navbarNav = document.getElementById("navbar-mobile");
            navbarNav.classList.toggle("hidden");
            let routerView = document.querySelector("#router-view");
            routerView.classList.toggle("brightness-50");
            document.body.classList.toggle('overflow-hidden')
        },
        closeBars() {
            let navbarNav = document.getElementById("navbar-mobile");
            let routerView = document.querySelector("#router-view");
            navbarNav.classList.add('hidden')
            routerView.classList.remove("brightness-50");

        }
    }

})