<template>
  <div>
    <div
      class="
        fixed
        top-0
        right-0
        left-0
        bg-white
        hidden
        overflow-auto
        animate__animated animate__fadeInRight
      "
      id="navbar-mobile"
      style="z-index: 2000"
    >
      <div class="container">
        <div class="flex justify-between py-2">
          <Logo />
          <div class="flex space-x-5">
            <div class="flex items-center">
              <b-icon icon="search" class="text-2xl"></b-icon>
            </div>
            <div class="cursor-pointer flex items-center">
              <svg
                width="24"
                height="24"
                viewBox="0 0 12 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click="$store.dispatch('bars')"
                class="mr-2"
              >
                <g id="bars">
                  <line
                    id="bottom-bar"
                    y1="10.5"
                    x2="12"
                    y2="10.5"
                    stroke="black"
                  />
                  <line
                    id="middle-bar"
                    y1="5.5"
                    x2="12"
                    y2="5.5"
                    stroke="black"
                  />
                  <line id="top-bar" y1="0.5" x2="12" y2="0.5" stroke="black" />
                </g>
              </svg>
            </div>
          </div>
        </div>
        <ul class="space-y-5">
          <li>
            <div
              class="no-underline cursor-pointer"
              @click="
                $store.dispatch('bars');
                $route.push('/');
              "
            >
              Home
            </div>
          </li>
          <li class="no-underline">
            <div
              class="accordion navlinkmobile flex-col space-y-5"
              id="accordionExample"
            >
              <!-- accordion about -->
              <div class="accordion-item border-0">
                <h2 class="accordion-header" id="headingOne">
                  <button
                    class="accordion-button p-0 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    @click="fullNav"
                  >
                    About
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <div
                      v-for="(item, i) in subMenuAbout"
                      :key="i"
                      class="
                        flex
                        space-x-4
                        border-2 border-blue-500
                        rounded-lg
                        mb-3
                        p-2
                      "
                      @click="
                        closeSubmenuAbout();
                        $router.push({ name: item.link });
                      "
                    >
                      <Img :img="item.img" />
                      <div>{{ item.title }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Ak space-y-5hir accordion About -->

              <!-- accordion solutions -->
              <div class="accordion-item border-0">
                <h2 class="accordion-header" id="headingTwo">
                  <button
                    class="accordion-button p-0 collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                    @click="fullNav"
                  >
                    Solutions
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <div
                      v-for="(item, i) in subMenuSolutions"
                      :key="i"
                      class="
                        flex
                        space-x-4
                        border-2 border-blue-500
                        rounded-lg
                        mb-3
                        p-2
                      "
                      @click="
                        closeSubmenuSolutions();
                        $router.push(`${item.link}`);
                      "
                    >
                      <Img :img="item.img" />
                      <div>{{ item.title }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <router-link class="no-underline text-black" to="/about/product"
              >Product</router-link
            >
          </li>
          <li>
            <router-link class="no-underline text-black" to="/portfolio"
              >Portfolio</router-link
            >
          </li>
          <li>
            <router-link class="no-underline text-black" to="/about/career"
              >Career</router-link
            >
          </li>
        </ul>
      </div>
    </div>

    <div class="fixed-top">
      <nav
        :class="{ scrolled: !view.atTopOfPage }"
        class="navbar navbar-expand-xl navbar-light bg-white p-0 animated"
      >
        <div class="container">
          <a class="navbar-brand" href="/">
            <Logo />
          </a>
          <div class="flex navlinkmobile space-x-5">
            <b-icon icon="search" class="text-2xl"></b-icon>
            <svg
              width="24"
              height="24"
              viewBox="0 0 12 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="$store.dispatch('bars')"
              class="mr-2"
            >
              <g id="bars">
                <line
                  id="bottom-bar"
                  y1="10.5"
                  x2="12"
                  y2="10.5"
                  stroke="black"
                />
                <line
                  id="middle-bar"
                  y1="5.5"
                  x2="12"
                  y2="5.5"
                  stroke="black"
                />
                <line id="top-bar" y1="0.5" x2="12" y2="0.5" stroke="black" />
              </g>
            </svg>
          </div>
          <div class="collapse navbar-collapse justify-end" id="navbarNav">
            <ul class="navbar-nav space-x-0 xl:space-x-4">
              <li class="nav-item py-3">
                <router-link class="nav-link" to="/">Home</router-link>
              </li>
              <li
                class="nav-item py-3 cursor-pointer submenulink"
                @mouseenter="megaMenuAbout"
                @mouseleave="megaMenuAboutClose"
              >
                <a
                  :class="
                    $route.name == 'Profile' ||
                    $route.name == 'Contact' ||
                    $route.name == 'Support' ||
                    $route.name == 'Success-story'
                      ? 'router-link-exact-active nav-link'
                      : 'nav-link'
                  "
                  >About</a
                >
              </li>
              <li
                class="nav-item py-3 cursor-pointer submenulink"
                @mouseenter="megaMenuSolutions"
                @mouseleave="megaMenuSolutionsClose"
              >
                <a
                  :class="
                    $route.name == 'HCM' || $route.name == 'payroll management'
                      ? 'router-link-exact-active nav-link'
                      : 'nav-link'
                  "
                  >Solutions</a
                >
              </li>
              <li class="nav-item py-3">
                <router-link class="nav-link" to="/about/product"
                  >Product</router-link
                >
              </li>
              <li class="nav-item py-3">
                <router-link class="nav-link" to="/portfolio"
                  >Portfolio</router-link
                >
              </li>
              <li class="nav-item py-3">
                <router-link class="nav-link" to="/about/career"
                  >Career</router-link
                >
              </li>
              <li class="nav-item text-black py-4 searchWeb">
                <b-icon icon="search"></b-icon>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <!-- navbar versi mobile -->
      <!-- mega menu about -->
      <div
        class="mega-menu mega-menu-about hidden relative z-50"
        id="megaMenuAbout"
        @mouseenter="megaMenuAbout"
        @click="megaMenuAboutClose"
        @mouseleave="megaMenuAboutClose"
      >
        <div
          class="
            container
            rounded-b-lg
            border-b-2 border-blue-500
            bg-white
            filter
            drop-shadow-lg
            bg-white
            py-3
          "
        >
          <div class="border-bottom">
            <p class="text-muted">About</p>
          </div>
          <ul class="d-flex flex-wrap">
            <div class="col-4 pt-4" v-for="(item, i) in subMenuAbout" :key="i">
              <div
                class="
                  card
                  border-0
                  hover:bg-blue-200
                  p-2
                  transition
                  duration-300
                  ease-in
                "
                @click="$router.push({ name: item.link })"
                style="cursor: pointer"
              >
                <div class="d-flex align-item-center">
                  <div class="flex items-center">
                    <Img
                      :img="item.img"
                      style="object-fit: contain"
                      width="32"
                      class="me-2"
                    />
                    <div class="card-title m-0">
                      {{ item.title }}
                      <!-- <div class="card-text text-muted">
                      {{ item.subtitle }}
                    </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
      <!-- mega menu solutions -->
      <div
        class="mega-menu mega-menu-solutions hidden relative z-50"
        id="megaMenuSolutions"
        @mouseenter="megaMenuSolutions"
        @click="megaMenuSolutionsClose"
        @mouseleave="megaMenuSolutionsClose"
      >
        <div
          class="
            container
            rounded-b-lg
            border-b-2 border-blue-500
            bg-white
            filter
            drop-shadow-lg
            py-3
          "
        >
          <div class="border-bottom">
            <p class="text-muted">Solutions</p>
          </div>
          <ul class="d-flex flex-wrap">
            <div
              class="col-4 pt-4"
              v-for="(item, i) in subMenuSolutions"
              :key="i"
            >
              <div
                class="card border-0"
                @click="$router.push(`${item.link}`)"
                style="cursor: pointer"
              >
                <div class="hover:bg-blue-200 p-2 d-flex align-item-center">
                  <div class="flex items-center">
                    <Img
                      :img="item.img"
                      style="object-fit: contain"
                      width="32"
                      class="mx-2"
                    />
                    <div class="card-title m-0">
                      {{ item.title }}
                      <!-- <div class="card-text text-muted">
                        {{ item.subtitle }}
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/components/Logo";
import Img from "@/components/Img";
// import "bootstrap/js/dist/collapse";

export default {
  components: {
    Logo,
    Img,
  },
  data() {
    return {
      view: {
        atTopOfPage: true,
      },
      subMenuAbout: [
        {
          img: "profile.svg",
          title: "Our Profile",
          // subtitle: "Lorem ipsum dolor sit amet.",
          link: "Profile",
        },
        {
          img: "about-support.svg",
          title: "Support",
          // subtitle: "Lorem ipsum dolor sit amet.",
          link: "Support",
        },
        {
          img: "success-story.svg",
          title: "Success Story",
          // subtitle: "Lorem ipsum dolor sit amet.",
          link: "Success-story",
        },
        {
          img: "contact.svg",
          title: "Contact",
          // subtitle: "Lorem ipsum dolor sit amet.",
          link: "Contact",
        },
      ],
      subMenuSolutions: [
        {
          img: "icon-1.svg",
          title: "Human Capital Management",
          // subtitle: "Lorem ipsum dolor sit amet.",
          link: "/hcm",
        },
        {
          img: "icon-2.svg",
          title: "Payroll Management",
          // subtitle: "Lorem ipsum dolor sit amet.",
          link: "/payroll-management",
        },
        {
          img: "icon-4.svg",
          title: "Project Management",
          // subtitle: "Lorem ipsum dolor sit amet.",
          link: "/project-management",
        },
        {
          img: "icon-4.svg",
          title: "Learning Management",
          // subtitle: "Lorem ipsum dolor sit amet.",
          link: "/learning-management",
        },
        {
          img: "icon-4.svg",
          title: "Sales Management",
          // subtitle: "Lorem ipsum dolor sit amet.",
          link: "/sales-management",
        },
        {
          img: "icon-4.svg",
          title: "Proctoring AI",
          // subtitle: "Lorem ipsum dolor sit amet.",
          link: "/proctoring-ai",
        },
        // {
        //   img: "icon-3.svg",
        //   title: "Facial Recognition",
        //   // subtitle: "Lorem ipsum dolor sit amet.",
        //   link: "/",
        // },
        // {
        //   img: "icon-4.svg",
        //   title: "Object Detection",
        //   // subtitle: "Lorem ipsum dolor sit amet.",
        //   link: "/",
        // },
        // {
        //   img: "icon-4.svg",
        //   title: "Line Counter",
        //   // subtitle: "Lorem ipsum dolor sit amet.",
        //   link: "/",
        // },
      ],
    };
  },
  methods: {
    closeNavbarMobile() {
      this.$store.dispatch("bars");
    },
    closeSubmenuAbout() {
      // let el = document.querySelector("#navbar-mobile");
      // el.classList.remove("hidden");
      this.$store.dispatch("bars");
    },
    closeSubmenuSolutions() {
      // let el = document.querySelector("#navbar-mobile");
      // el.classList.remove("hidden");
      this.$store.dispatch("bars");
    },
    megaMenuAbout() {
      let el = document.getElementById("megaMenuAbout");
      el.classList.remove("hidden");
    },
    megaMenuAboutClose() {
      let el = document.getElementById("megaMenuAbout");
      el.classList.add("hidden");
    },
    megaMenuSolutions() {
      let el = document.getElementById("megaMenuSolutions");
      el.classList.remove("hidden");
    },
    megaMenuSolutionsClose() {
      let el = document.getElementById("megaMenuSolutions");
      el.classList.add("hidden");
    },
    fullNav() {
      let el = document.getElementById("navbar-mobile");
      el.classList.toggle("h-full");
    },

    // the function to call when the user scrolls, added as a method
    handleScroll() {
      // when the user scrolls, check the pageYOffset
      if (window.pageYOffset > 0) {
        // user is scrolled
        if (this.view.atTopOfPage) this.view.atTopOfPage = false;
      } else {
        // user is at top of page
        if (!this.view.atTopOfPage) this.view.atTopOfPage = true;
      }
    },
  },
};
window.addEventListener("scroll", () => {
  const nav = document.querySelector(".navbar");
  if (window.pageYOffset > 0) {
    nav.classList.add("scrolled");
  } else {
    nav.classList.remove("scrolled");
  }
});
</script>
<style scoped>
.bot-bar-rotate {
  transform: rotate(-45deg) translate(-6px, -4px);
}
.top-bar-rotate {
  transform: rotate(45deg) translateX(1px);
}
#middle-bar,
#top-bar,
#bottom-bar {
  transition: 0.3s ease;
}
.open {
  transform: translateX(20px);
  opacity: 0;
}
.mega-menu {
  animation: fadein 0.3s ease;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.router-link-exact-active {
  font-weight: 1000;
  border-bottom: 3px solid #2658a8;
}

@media only screen and (max-width: 992px) {
  /* #accordion {
    display: flex;
  } */
  .navlinkmobile {
    display: flex;
  }
  .submenulink {
    display: none;
  }
  .searchWeb {
    display: none;
  }
}
@media only screen and (min-width: 992px) {
  /* #accordion {
    display: flex;
  } */
  .navlinkmobile {
    display: flex;
  }
  .submenulink {
    display: none;
  }
  .searchWeb {
    display: none;
  }
}
@media only screen and (min-width: 1200px) {
  /* #accordion {
    display: none;
  } */
  .navlinkmobile {
    display: none;
  }
  .submenulink {
    display: block;
  }
  .searchWeb {
    display: block;
  }
}
nav {
  z-index: 10;
}

nav.scrolled {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 7px 0px;
}
/* .add-shadow{
  box-shadow: rgba(60, 64, 67, 0.3) 0px 10px 11px 0px
} */
</style>
