<template>
  <div>
    <div class="news">
      <div class="container py-5">
        <div class="d-flex flex-column text-center">
          <p class="text-3xl font-bold">Get The Latest Birutekno News !</p>
          <p class="">We never send spam. You can unsubscribe at anytime</p>
          <div class="d-flex justify-content-center">
            <form action="" class="flex space-x-4">
              <div class="">
                <input
                  type="text"
                  class="form-control mb-3"
                  size="40"
                  id="text"
                />
              </div>
              <div class="">
                <button type="submit" class="btn bg-orange text-white">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <footer class="py-5">
        <p class="text-muted">member of</p>
        <Logo />
        <h1 class="text-3xl font-bold mt-3">How can we help you ?</h1>
        <div class="row justify-content-between">
          <div class="col">
            <p>
              <span class="text-primary fw-bold">Get in touch</span> with
              us or
              <span class="text-primary fw-bold"> find our office</span>
              now.
            </p>
          </div>
          <div class="col">
            <ul class="nav justify-content-end">
              <li class="nav-item">
                <router-link
                  to="/about/contact#"
                  class="nav-link px-2 text-muted"
                  >Contact Us</router-link
                >
              </li>
              <li class="nav-item">
                <a href="https://policies.google.com/terms" class="nav-link px-2 text-muted">Terms of us</a>
              </li>
              <li class="nav-item">
                <a href="https://policies.google.com/privacy" class="nav-link px-2 text-muted">Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="py-2 my-2 d-flex justify-content-end border-bottom">
          <ul class="list-unstyled d-flex space-x-3">
            <li
              v-for="item in socialMedia"
              :key="item"
              class="
                hover:cursor-pointer
                transform
                hover:translate-y-1
                transition
                duration-300
              "
            >
              <a :href="item.link" target="_blank" rel="noopener noreferrer"
                ><Img :img="item.img"
              /></a>
            </li>
          </ul>
        </div>
        <p class="text-center">© 2022 Birutekno inc. All All Reserved</p>
      </footer>
    </div>
  </div>
</template>

<script>
import Img from "@/components/Img";
import Logo from "@/components/Logo";
export default {
  components: {
    Img,
    Logo,
  },
  data() {
    return {
      socialMedia: [
        {
          img: "facebook.svg",
          title: "Human Capital Management",
          link: "https://id-id.facebook.com/birutekno.inc/",
        },
        {
          img: "twitter.svg",
          title: "Human Capital Management",
          link: "https://twitter.com/birutekno",
        },
        {
          img: "instagram.svg",
          title: "Human Capital Management",
          link: "https://www.instagram.com/birutekno.inc/?hl=id",
        },
        {
          img: "youtube.svg",
          title: "Human Capital Management",
          link: "https://www.youtube.com/c/BiruteknoBandung",
        },
      ],
    };
  },
};
</script>

<style scoped>
svg {
  font-size: 32px;
}
.news {
  width: 100%;
  background: linear-gradient(88.02deg, #ededed 20.98%, #d2d2d2 79.56%);
}
</style>